<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="采购单ID" >
                <el-input v-model="search.caiGouDanID" size="small" />
            </x-search-item>
            <x-search-item label="采购时间" textWidth="138px" hasEnd>
                <el-date-picker v-model="search.caiGouSJBegin" size="small" type="datetime" />
                <el-date-picker v-model="search.caiGouSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <x-search-item label="供货商" >
                <el-input v-model="search.gongHuoShang" size="small" />
            </x-search-item>
            <x-search-item label="仓库" >
                <el-input v-model="search.cangKu" size="small" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="caiGouDanID" label="采购单ID" />
            <el-table-column width="180" prop="caoZuoRen" label="操作人" />
            <el-table-column width="180" prop="caoZuoRenID" label="操作人ID" />
            <el-table-column width="180" prop="caiGouLX" label="采购类型" />
            <el-table-column width="180" prop="caiGouSJ" label="采购时间" />
<!--            <el-table-column width="180" prop="caoZuoSJ" label="操作时间" />-->
            <el-table-column width="180" prop="gongHuoShang" label="供货商" />
            <el-table-column width="180" prop="gongHuoShangID" label="供货商ID" />
            <el-table-column width="180" prop="cangKu" label="仓库" />
            <el-table-column width="180" prop="cangKuID" label="仓库ID" />
            <el-table-column width="180" prop="heJi" label="合计（种）" />
            <el-table-column width="180" prop="jinE" label="金额" />
            <el-table-column width="180" prop="beiZhu" label="备注" />
            <el-table-column width="100" align="center" label="操作" fixed="right">
                <el-button slot-scope="{row}" type="primary" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 详情页 -->
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import {query} from "@/service/cggl/CaiGouDan";
    import XTableBase from "@/components/x/XTableBase";
    import Detail from "@/view/cggl/cgd/CaiGouDanDetail";

    export default {
        name: "CaiGouDanQuery",
        mixins: [XTableBase],
        components: {Detail},
        data() {
            this.refreshService = query;
            return {
                search: {
                    caiGouDanID: '',
                    caiGouSJBegin: '',
                    caiGouSJEnd: '',
                    gongHuoShang: '',
                    cangKu: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>